.ant-tabs-nav-scroll{
  margin-left: auto;
  margin-right: auto;
}
.ant-tabs-nav-wrap{
  display: flex;
}

.form-select{
  max-width: 500px;
  box-shadow: 0 11px 19px 0 rgba(0, 0, 0, 0.1) !important;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
.warn{
  color: #fadb14;
}

.warn:hover{
  border-color: #fadb14;
  color: #fadb14;
}