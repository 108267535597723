body {
  font-family: 'Kanit', sans-serif !important;
}


.ant-table-thead > tr > th .ant-table-filter-icon > svg{
  margin-left: -10px !important;
}

.verify-form{
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 11px 19px 0 rgba(0, 0, 0, 0.1) !important;
  padding: 1rem !important;
}

.select-form{
  box-shadow: 0 11px 19px 0 rgba(0, 0, 0, 0.1) !important;
}

.App {
  text-align: center;
}

.min-70{
  min-height: 70vh;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-page{
  display: flex;
  min-height: 100vh;
  background: rgb(240, 57, 117) ; 
  background: -webkit-linear-gradient(left,rgba(211, 53, 106, .8),rgba(240, 57, 117,1)); 
  background: -o-linear-gradient(right,rgba(240, 57, 117, .8),rgba(240, 57, 117,1)); 
  background: -moz-linear-gradient(right,rgba(240, 57, 117, .8),rgba(240, 57, 117,1)); 
  background: linear-gradient(to right, rgba(240, 57, 117, .8), rgba(240, 57, 117,1)); 
  /* background-image: linear-gradient(to right, #f3b755, #e5366f,#f03975, #e5366f, #f3b755); */
}

 .login-form {
   min-width: 250px;
  padding: 1rem !important;
  margin: auto !important;
  background: rgba(234,234,234,1);
  border-radius: .4rem;
  box-shadow: 2px 2px 5px;
}
 .login-form-forgot {
  float: right;
}
 .login-form-button {
  width: 100%;
}

.regis-form {
 padding: 1rem !important;
 margin: auto !important;
 background: rgba(234,234,234,1);
 border-radius: .4rem;
 box-shadow: 2px 2px 5px;
 max-width: 90%;
 margin-top: 2rem !important;
 margin-bottom: 2rem !important;
 font-size: 1rem;
}

.profile-form{
  padding: 1rem !important;
  margin: auto !important;
  background: rgba(234,234,234,.5);
  border-radius: .4rem;
  /* box-shadow: 2px 2px 5px; */
  max-width: 90%;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  font-size: 1rem;
}

.size-08{
  font-size: .8rem !important;
}
.size-10{
  font-size: 1rem !important;
}
.size-11{
  font-size: 1.1rem !important;
}
.size-12{
  font-size: 1.2rem !important;
}
.size-15{
  font-size: 1.5rem !important;
}
.size-20{
  font-size: 2rem !important;
}

.mx-auto{
  margin: auto !important;
}

.ml-auto{
  margin-left: auto !important;
}

.mr-auto{
  margin-right:  auto !important;
}

.mb-auto{
  margin-bottom: auto !important;
}

.mt-auto{
  margin-top: auto !important;
}


.text-center{
  text-align: center !important;
}

.text-left{
  text-align: left !important;
}

.text-right{
  text-align: right !important;
}

.pt-3{
  padding-top: 1rem !important;
}

.pb-3{
  padding-bottom: 1rem !important;
}

.pl-3{
  padding-left: 1rem !important;
}

.pr-3{
  padding-right: 1rem !important;
}

.pr-6{
  padding-right: 2rem !important;
}
.mb-0{
  margin-bottom: 0rem !important;
}


.mt-3{
  margin-top: 1rem !important;
}

.mb-3{
  margin-bottom: 1rem !important;
}

.ml-3{
  margin-left: 1rem !important;
}

.mr-3{
  margin-right: 1rem !important;
}

.mt-4{
  margin-top: 1.25rem !important;
}

.mb-4{
  margin-bottom: 1.25rem !important;
}

.ml-4{
  margin-left: 1.25rem !important;
}

.mr-4{
  margin-right: 1.25rem !important;
}


.pt-2{
  padding-top: .5rem !important;
}

.pb-2{
  padding-bottom: .5rem !important;
}

.pl-2{
  padding-left: .5rem !important;
}

.pr-2{
  padding-right: .5rem !important;
}

.mt-2{
  margin-top: .5rem !important;
}

.mb-2{
  margin-bottom: .5rem !important;
}

.ml-2{
  margin-left: .5rem !important;
}

.mr-2{
  margin-right: .5rem !important;
}
.logo-top{ 
  height: 50px;
  /* background: rgba(230, 46, 147, 0.2); */
  padding: 0;
  margin: 10px;
  text-align: center;
  align-items: center;
}

.radius{
  border-radius: 100%;
}

.radius-15{
  border-radius: 1.5rem !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: rgb(224, 224, 224);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(214, 214, 214);
}




.align-auto{
  align-items: center;
}

.c-main{
  color: #f03975 !important;
}

.c-gray{
  color: gray !important;
}

.opa-05{
  opacity: 0.5 !important;
}
.c-suc{
  color: green !important;
}


.c-sub{
  color: #f8d00f !important;
}

.c-w{
  color: #fff !important;
}

.c-b{
  color: #2c2e2c !important;
}

.b-main{
  background-color: #f03975 !important;
}

.b-sub{
  background-color: #f8d00f !important;
}

.b-w{
  background-color: #fff !important;
}

.bg-w{
  background-color: #fff !important;
}

.b-234{
  background-color: rgb(234, 234, 234) !important;
}
.b-180{
  background-color: rgb(180, 180, 180) !important;
}
.b-221{
  background-color: #DDDDDD !important;
}

.pointer{
  cursor:  pointer !important;
}

.w-100{
  width: 100%;
}
.ab{
  position: absolute !important;
}

.rela{
  position: relative !important;
}

.rela .ant-affix{
  position: sticky !important;
}

.bt{
  bottom: 0px;
}

.t-0{
  top: 0px;
}

.b-0{
  bottom: 0px;
}

.hide{
  display: none;
}

.pd-2{
  padding: .5rem !important;
}

.pd-3{
  padding: 1rem !important;
}

.pd-1{
  padding: .25rem !important;
}

.mg-0{
  margin: 0px !important;
}
.mg-1{
  margin: .25rem !important;
}

.mg-2{
margin: .5rem !important;
}

.mg-3{
  margin: 1rem !important;
}

.border{
  border: .5px solid #e8e8e8;
}

.h-100{
  height: 100%;
}

.d-flex{
  display: flex !important;
}

.center{
  margin: auto !important;
  text-align: center;
}

.max-90{
  max-height: 84vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.x-auto ,.ReactVirtualized__Grid__innerScrollContainer{
  overflow-x: auto !important;
  overflow-y: hidden;
}

.w-fit{
  min-width: fit-content !important
}
.bold{
  font-weight: bold !important;
}

.l-0{
  left: 0;
}

.r-0{
  right: 0;
}

.t-0{
  top: 0;
}

.b-0{
  bottom: 0;
}

.pb-6{
  padding-bottom: 2rem !important;
}

.default{
  cursor: default !important;
}

.h-3{
  height: 3rem !important;
  line-height: 3rem !important;
}

.h-5{
  height: 5rem !important;
  line-height: 5rem !important;
}

.h-2{
  height: 2rem !important;
  line-height: 2rem !important;
}

.radius-0{
  border-radius: 0 !important;
}

.weight-400{
  font-weight: 400 !important;
}

.weight-200{
  font-weight: 200 !important;
}
.input-h-5 input{
  height: 5rem !important;
  font-size: 5rem;
  line-height: 5rem;
  text-align: center;
}

.size-50{
  font-size: 5rem !important;
}

.raduis-1{
  border-radius: 1rem !important;
}

.raduis-1 .ant-input-number-handler-wrap{
  border-radius: 1rem;
}



.ant-modal-confirm-btns button{
  font-size: 1.5rem;
  line-height: 2rem;
}

.ant-modal-confirm-title{
  font-size: 1.5rem !important;
}

.ant-modal-confirm-body  svg{
  margin-top: .3rem;
}

.card .ant-card-body{
  padding: 0rem;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-y: hidden;
  overflow-x: hidden;
}

.shadow{
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.line-10{
  line-height: 1rem !important;
}
.line-11{
  line-height: 1.1rem !important;
}

.line-12{
  line-height: 1.2rem !important;
}

.line-15{
  line-height: 1.5rem !important;
}

.ant-message-notice span{
  font-size: 1rem;
  font-weight: bold;
}
.b-grad{
  background-image: linear-gradient(to right, #f3b755, #e4336e,#f03975, #e5366f, #f3b755) !important;
}
.ant-layout-sider .ant-menu-item .anticon{
  vertical-align: 1px;
}
.ant-layout-sider .ant-menu-dark .ant-menu-submenu-selected{
  background-color: #f8d00f !important;
}

.ant-layout-sider .anticon .not-active  img{
  filter: brightness(0) invert(1);
}

.ant-progress-line:not(.ant-progress-status-success)  .ant-progress-inner{
  border: 1px solid #f03975;
}

.b-216{
  background-color: rgb(216,216,216);
}

.ReactVirtualized__Grid:focus,.ReactVirtualized__Grid__innerScrollContainer:focus{
  outline: unset !important;
}
.ant-message-info .anticon{
  color: #f03975 !important;
}

.under-hover:hover{
  text-decoration: underline;
}

.ant-page-header{
  padding: 8px 16px !important;
}

.ant-page-header-content{
  padding-top: 0px !important;
}

.h-100{
  height: 100% !important;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}